export const LOGIN_INITIATE = "LOGIN_INITIATE"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"

export const SIGNUP_INITIATE = "SIGNUP_INITIATE"
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS"
export const SIGNUP_FAILED = "SIGNUP_FAILED"

export const SIGN_OUT = "SIGN_OUT"

export const PAGE_LIST = "PAGE_LIST"
export const ARTICLE_LISTING = "ARTICLE_LISTING"
export const ARTICLE_LISTING_META = "ARTICLE_LISTING_META"
export const USER_LISTING = "USER_LISTING"
export const USER_LISTING_META = "USER_LISTING_META"
export const CHALLENGE_LISTING = "CHALLENGE_LISTING"
export const CHALLENGE_LISTING_META = "CHALLENGE_LISTING_META"
export const USER_CHALLENGE_LISITING = "USER_CHALLENGE_LISITING"
export const USER_CHALLENGE_LISITING_META = "USER_CHALLENGE_LISITING_META"

export const PAGE_LISTING = "PAGE_LISTING"
export const PAGE_LISTING_META = "PAGE_LISTING_META"

export const NOTIFICATION_LIST = "NOTIFICATION_LIST"
export const UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE"
export const REFRESH_NOTIFICATION_LIST = "REFRESH_NOTIFICATION_LIST"

let Config = {
  ENVIRONMENT: "PRODUCTION",
  ENVIRONMENTS: {
    DEVELOPMENT: {
      API_URL: "https://api-stg.the5spheresoffit.com",
      // API_URL: "http://172.16.203.30:6600",
      PERSIST_SECRET_KEY: "Sphere of fit Admin",
    },
    PRODUCTION: {
      API_URL: "https://api.the5spheresoffit.com",
      PERSIST_SECRET_KEY: "Sphere of fit Admin",
    },
  },
}

Config.env = () => {
  return Config.ENVIRONMENTS[Config.ENVIRONMENT]
}

export default Config

import {
    NOTIFICATION_LIST,
    UPDATE_CURRENT_PAGE,
    REFRESH_NOTIFICATION_LIST
} from "redux/constant/authConstant"

const initialState = {
    notifications: [],
    currentPage: 1,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION_LIST:
            return {
                ...state,
                notifications: state.currentPage === action.payload.currentPage
                    ? action.payload.notifications
                    : state.notifications.concat(action.payload.notifications),
            }
        case REFRESH_NOTIFICATION_LIST:
            return {
                ...state,
                notifications: action.payload.notifications,
            }
        case UPDATE_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload.currentPage
            }
        default:
            return state
    }
}

import {
  CHALLENGE_LISTING,
  CHALLENGE_LISTING_META,
} from "redux/constant/authConstant"

const initialState = {
  challengeListing: [],
  meta: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHALLENGE_LISTING:
      return {
        ...state,
        challengeListing: action.payload,
      }
    case CHALLENGE_LISTING_META: {
      return {
        ...state,
        meta: action.payload,
      }
    }
    default:
      return state
  }
}

import {
  ARTICLE_LISTING,
  ARTICLE_LISTING_META,
} from "redux/constant/authConstant"

const initialState = {
  articleListing: [],
  meta: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ARTICLE_LISTING:
      return {
        ...state,
        articleListing: action.payload.map(item => {
          return { ...item, checked: false }
        }),
      }
    case ARTICLE_LISTING_META: {
      return {
        ...state,
        meta: action.payload,
      }
    }
    default:
      return state
  }
}

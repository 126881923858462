import { combineReducers } from "redux"
import { SIGN_OUT } from "redux/constant/authConstant"
import authReducer from "./authReducer"

import Layout from "../../store/layout/reducer"

// Authentication
import Login from "../../store/auth/login/reducer"
import Account from "../../store/auth/register/reducer"
import ForgetPassword from "../../store/auth/forgetpwd/reducer"
import Profile from "../../store/auth/profile/reducer"

//E-commerce
import ecommerce from "../../store/e-commerce/reducer"

//Calendar
import calendar from "../../store/calendar/reducer"

//chat
import chat from "../../store/chat/reducer"

//crypto
import crypto from "../../store/crypto/reducer"

//invoices
import invoices from "../../store/invoices/reducer"

//projects
import projects from "../../store/projects/reducer"

//tasks
import tasks from "../../store/tasks/reducer"

//contacts
import contacts from "../../store/contacts/reducer"

//mails
import mails from "../../store/mails/reducer"

//Dashboard
import Dashboard from "../../store/dashboard/reducer"

//Dasboard saas
import DashboardSaas from "../../store/dashboard-saas/reducer"
import articleReducer from "./articleReducer"
import userReducer from "./userReducer"
import challengeReducer from "./challengeReducer"
import pageReducer from "./pageReducer"
import notificationReducer from "./notificationReducer"
const appReducer = combineReducers({
  auth: authReducer,
  article: articleReducer,
  user: userReducer,
  challenge: challengeReducer,
  page: pageReducer,
  notifications:notificationReducer,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
})

const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer

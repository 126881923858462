import {
  USER_LISTING_META,
  USER_LISTING,
  USER_CHALLENGE_LISITING,
  USER_CHALLENGE_LISITING_META,
} from "redux/constant/authConstant"

const initialState = {
  userListing: [],
  userChallengeListing: [],
  userChallengeListingMeta: {},
  meta: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_LISTING:
      return {
        ...state,
        userListing: action.payload.map(item => {
          return { ...item, checked: false }
        }),
      }
    case USER_LISTING_META: {
      return {
        ...state,
        meta: action.payload,
      }
    }
    case USER_CHALLENGE_LISITING:
      return {
        ...state,
        userChallengeListing: action.payload,
      }
    case USER_CHALLENGE_LISITING_META: {
      return {
        ...state,
        userChallengeListingMeta: action.payload,
      }
    }
    default:
      return state
  }
}

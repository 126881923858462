import {
  LOGIN_FAILED,
  LOGIN_INITIATE,
  LOGIN_SUCCESS,
} from "redux/constant/authConstant"

const initialState = {
  fetching: false,
  isAuthenticated: false,
  user: {},
  token: "",
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_INITIATE:
      return {
        ...state,
        fetching: true,
      }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        fetching: false,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      }
    }
    case LOGIN_FAILED:
      return {
        ...state,
        fetching: false,
      }

    default:
      return state
  }
}

export const API_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  UPDATE: "UPDATE",
}

export const API_STATUS = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  SERVER_ERROR: 500,
  PAYLOAD_TOO_LARGE: 413,
}

export const API_CONTENT_TYPE = {
  JSON: "application/json",
  FORM_DATA: "multipart/form-data",
}

export const API_URL = {
  AUTH: {
    LOGIN: "/users/login",
    SIGNUP: "/users/admin/create-user",
    FORGOT: "/users/reset/password",
    UPDATE: "/users/admin/update/profile",
    OTP: "/users/reset/verify/verification-code",
    NEW_PASSWORD: "/users/reset/new-password",
    CHANGE_PASSWORD: "/users/change-password",
  },
  PAGE: {
    LIST: "/pages/pages",
    EDIT: "/pages/pages/edit",
    SAVE: "/pages/pages/edit/save",
  },
  HOW_TO_USE: {
    get: "/Articles/how-to-use",
    save: "/Articles/how-to-use/save-and-update",
  },
  ARTICLE_CATEGORIES: {
    LIST: "/Articles/categories/list",
    SAVE: "/Articles/categories/save",
    EDIT: "/Articles/categories/edit",
  },
  ARTICLES: {
    LIST: "/Articles/admin/list",
    SAVE: "/Articles/save",
    EDIT: "/Articles/edit",
    DELETE: "/Articles/delete",
    UPDATE: "/Articles/update",
    STATUS: "/Articles/toggle-status",
    BULK: "/Articles/bulk-delete",
  },
  NOTIFICATIONS: {
    LIST: "/users/get-all-notification",
  },
  USER: {
    ALL_USER: "/users/get-all-user",
    VIEW_ID: "/users/get-all-user/view",
    DELETE: "/users/get-all-user/delete",
    UPDATE: "/users/update",
    DASHBOARD: "/users/get-dashboard",
    STATUS: "/users/admin/toggle-user-status",
    VERIFY: "/users/admin/verify-user",
    BULK: "/users/bulk-user/delete",
    CHALLENGE: "/challenges-goals/user-challenge-goal-and-task-list",
  },
  CHALLENGE: {
    LIST: "/challenges-goals/challenges-list-admin",
    VIEW: "/challenges-goals/challenges-view",
    EDIT: "/challenges-goals/challenges-edit",
    DELETE: "/challenges-goals/challenges-delete",
    ADD: "/challenges-goals/challenges-add",
  },
}

import { PAGE_LISTING, PAGE_LISTING_META } from "redux/constant/authConstant"

const initialState = {
  pageListing: [],
  meta: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PAGE_LISTING:
      return {
        ...state,
        pageListing: action.payload,
      }
    case PAGE_LISTING_META: {
      return {
        ...state,
        meta: action.payload,
      }
    }
    default:
      return state
  }
}
